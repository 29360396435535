import { HowDoesItWorkFragment } from '@codegen/cmsUtils';
import HowDoesItWorkSection, {
  HowDoesItWorkSectionProps,
} from './HowDoesItWorkSection';
import HowDoesItWorkSectionV2, {
  HowDoesItWorkSectionV2Props,
} from './HowDoesItWorkSectionV2';

interface Props {
  item: HowDoesItWorkFragment;
  variant?: string;
}

const HowDoesItWorkContainer = ({ item }: Props) => {
  switch (item.variant) {
    case 'icons-to-the-left':
      return (
        <HowDoesItWorkSectionV2
          imageCards={
            item.imageCards as HowDoesItWorkSectionV2Props['imageCards']
          }
          isTile={item.isTile}
          key={`${item.id}HowDoesItWork2`}
        />
      );
    default:
      return (
        <HowDoesItWorkSection
          imageCards={
            item.imageCards as HowDoesItWorkSectionProps['imageCards']
          }
          isTile={item.isTile}
          key={`${item.id}HowDoesItWork`}
        />
      );
  }
};

export default HowDoesItWorkContainer;
