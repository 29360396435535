import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import { GridRow, GridColumn } from '@ui/components/Grid/Grid';
import Paragraph from '@ui/components/Paragraph/Paragraph';

interface Props {
  alignment?: Maybe<AlignmentOptions>;
  text: string;
}

const StyledParagraph = styled(Paragraph)<{
  alignment?: Maybe<AlignmentOptions>;
}>(
  ({ alignment }) => css`
    text-align: ${alignment || 'unset'};
  `,
);

const ParagraphWrapper = ({ alignment, text }: Props) => {
  return (
    <GridRow display="block">
      <GridColumn>
        <StyledParagraph alignment={alignment}>{text}</StyledParagraph>
      </GridColumn>
    </GridRow>
  );
};

export default ParagraphWrapper;
