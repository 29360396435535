import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VendorDisplayFragment } from '@codegen/cmsUtils';
import { GridRow, GridColumn } from '@ui/components/Grid/Grid';
import Image from '@ui/components/Image';
import Link from '@ui/components/Link/Link';
import { createTypography, mqMin } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';

interface Props {
  data: VendorDisplayFragment;
}

const StyledGridRow = styled(GridRow)(({ theme: { typography } }) =>
  createTypography(typography.body01),
);

const Container = styled.div<{
  borderRadius?: Maybe<number>;
  useBorder?: Maybe<boolean>;
  useFullWidth?: Maybe<boolean>;
}>(({ borderRadius, theme: { colours, shape }, useBorder, useFullWidth }) => [
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: ${legacySpacings.Medium}px ${legacySpacings.Medium}px;
  `,
  useBorder &&
    css`
      padding: ${legacySpacings.Medium}px;
      border: 1px solid ${colours.border.default};
      border-radius: ${borderRadius ?? shape.borderRadiusS}px;
    `,
  useFullWidth &&
    css`
      justify-content: space-between;

      ${mqMin.Small} {
        gap: ${legacySpacings.ExtraLarge}px ${legacySpacings.ExtraExtraLarge}px;
      }
    `,
]);

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'restrictWidth',
})<{
  restrictWidth?: Maybe<boolean>;
}>(({ restrictWidth }) => [
  restrictWidth &&
    css`
      width: auto;
      max-width: 140px;
      height: 62px;
      ${mqMin.ExtraExtraSmall} {
        max-width: 130px;
      }
      ${mqMin.Medium} {
        max-width: 120px;
      }
    `,
]);

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const VendorDisplayWrapper = ({ data }: Props) => {
  const useFullContainerWidth = data.alignment === 'useFullWidth';

  return (
    <StyledGridRow display="block">
      <GridColumn>
        <Container
          borderRadius={data.borderRadius}
          useBorder={data.useBorder}
          useFullWidth={useFullContainerWidth}
        >
          {data.vendorDisplayInfo.map(({ link, logoHeight, vendor }) => {
            if (!vendor.logo.url) {
              return null;
            }

            if (link) {
              return (
                <StyledLink
                  href={link.href}
                  key={vendor.name}
                  target={link.target ?? undefined}
                >
                  <StyledImage
                    alt={vendor.iata}
                    height={logoHeight ?? 30}
                    objectFit="contain"
                    restrictWidth={useFullContainerWidth}
                    src={vendor.logo.url}
                    width={140}
                  />
                </StyledLink>
              );
            }

            return (
              <StyledImage
                alt={vendor.iata}
                height={logoHeight ?? 30}
                key={vendor.name}
                objectFit="contain"
                restrictWidth={useFullContainerWidth}
                src={vendor.logo.url}
                width={140}
              />
            );
          })}
        </Container>
      </GridColumn>
    </StyledGridRow>
  );
};

export default VendorDisplayWrapper;
