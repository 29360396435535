import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTypography } from '../../styles/base';

export interface Props {
  children: React.ReactNode;
  className?: string;
}

const StyledParagraph = styled.p<Props>(({ theme }) => [
  createTypography(theme.typography.body01),
  css`
    color: ${theme.colours.text.default};
  `,
]);

const Paragraph = ({ children, className }: Props) => {
  return <StyledParagraph className={className}>{children}</StyledParagraph>;
};

export default Paragraph;
