import { HoverCardFragment } from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import { LayoutGrid } from '@ui/components/Grid/Grid';
import HoverCard from '@ui/components/HoverCard/HoverCard';
import { legacySpacings } from '@ui-v2/theme/layout';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useSettings } from '@web/context/SettingsContext';

interface Props {
  cards: Maybe<HoverCardFragment[]>;
}

const HoverCardWrapper = ({ cards }: Props) => {
  const { locale } = useConstants();
  const { residency } = useSettings();

  if (!cards) {
    return null;
  }

  return (
    <LayoutGrid
      gap={legacySpacings.Small}
      gridTemplateColumns={[
        'auto',
        'auto',
        'auto',
        'auto',
        `repeat(${cards.length}, 1fr)`,
      ]}
      px={[0, 0, DisplayType.Medium, DisplayType.Medium, DisplayType.Large]}
    >
      {cards.map((cardInfo) => (
        <HoverCard
          backgroundImage={cardInfo.backgroundImage}
          externalLink={getVendorLink({
            links: cardInfo.externalLink,
            locale,
            residency,
          })}
          key={cardInfo.id}
          text={cardInfo.hoverText?.value}
          title={cardInfo.title?.value}
        />
      ))}
    </LayoutGrid>
  );
};

export default HoverCardWrapper;
