import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ColorFragment,
  ImageCardFragment,
  SpecificTypographyFragment,
} from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import ImageCard, { Title, Description } from '@ui/components/Card/ImageCard';
import { GridRow, GridColumn } from '@ui/components/Grid/Grid';
import { tileStyles } from '@ui/components/Tile/Tile';
import { createLegacyTypography } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';

interface ExtendedImageCardFragment extends ImageCardFragment {
  imageObjectFit?: CSSProperties['objectFit'];
}

export interface HowDoesItWorkSectionProps {
  imageCards: ExtendedImageCardFragment[];
  isTile?: Maybe<boolean>;
}

const StyledCard = styled(ImageCard)<{
  descriptionColor?: Maybe<ColorFragment>;
  isTile?: Maybe<boolean>;
  titleColor?: Maybe<ColorFragment>;
  titleTypography?: Maybe<SpecificTypographyFragment>;
}>(({ descriptionColor, isTile, theme, titleColor, titleTypography }) => [
  css`
    ${Title} {
      margin-bottom: ${theme.spacings['8']}px;
      ${titleTypography && createLegacyTypography(titleTypography)}
    }
  `,
  titleColor &&
    css`
      ${Title} {
        color: ${titleColor.main};
      }
    `,
  descriptionColor &&
    css`
      ${Description} {
        color: ${descriptionColor.main};
      }
    `,
  isTile &&
    css`
      ${tileStyles(theme)}
      display: flex;
      height: 100%;
    `,
]);

const HowDoesItWorkSection = ({
  imageCards,
  isTile,
}: HowDoesItWorkSectionProps) => (
  <GridRow
    direction={['column', 'column', 'column', 'row']}
    gap={legacySpacings.ExtraExtraLarge}
    px={[
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Large,
    ]}
  >
    {imageCards.map((imageCard) => (
      <GridColumn key={imageCard.id} px={0} width={[12, 12, 12, 4]}>
        <StyledCard
          description={imageCard.description?.value}
          descriptionColor={imageCard.descriptionColor}
          image={imageCard.image}
          isTile={isTile}
          objectFit={imageCard.imageObjectFit}
          title={imageCard.title?.value}
          titleColor={imageCard.titleColor}
          titleTypography={imageCard.titleTypography}
        />
      </GridColumn>
    ))}
  </GridRow>
);

export default HowDoesItWorkSection;
