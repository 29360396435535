import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import { DisplayType } from '@shared/types/enums';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import Cover from '@ui/components/Cover/Cover';
import MediaQuery from '@ui/components/Responsive/MediaQuery';
import { mqMin } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';
import usePartnerPassengersConfig from '@web/context/hooks/usePassengersConfig';
import { SearchWidgetProvider } from '../../Search/contexts/SearchWidgetContext';
import useSearchQueryParamsWithLocalStorage from '../../Search/hooks/useSearchQueryParamWithLocalStorage';
import SearchWidgetErrorBoundary from '../../Search/SearchWidgetErrorBoundary';
import FrontPageSearchWidgetContainer from '../FrontPageSearchWidgetContainer';
import useGetFrontPageData from '../hooks/useGetFrontPageData';

const StyledCover = styled(Cover)<{
  hasCoverContent?: boolean;
  spaceAbove?: Maybe<number>;
  spaceBelow?: Maybe<number>;
}>(({ hasCoverContent, spaceAbove, spaceBelow, theme: { spacings } }) => [
  hasCoverContent &&
    css`
      min-height: 340px;

      ${mqMin.Small} {
        padding: ${legacySpacings.ExtraLarge}px 0;
      }

      ${mqMin.Medium} {
        min-height: 300px;
        padding: ${spacings['48']}px 0;
        padding-top: ${spaceAbove ? `${spaceAbove}px` : null};
        padding-bottom: ${spaceBelow ? `${spaceBelow}px` : null};
      }
    `,
]);

const FrontPageCoverContainer = () => {
  const { cover } = useGetFrontPageData();
  const {
    backgroundTextColor,
    image,
    showTitleOnTablets,
    spaceAbove,
    spaceBelow,
    subtitleColor,
    text,
    textAlign,
    titleColor,
    titleTypography,
    useFloatingSearchWidget,
  } = cover;

  const { passengerRules } = usePartnerPassengersConfig();

  const {
    currency,
    departureDate,
    destinations,
    isOneWay,
    origins,
    paxTypeAges,
    residency,
    returnDate,
  } = useSearchQueryParamsWithLocalStorage(passengerRules);

  return (
    <SearchWidgetErrorBoundary>
      <SearchWidgetProvider
        currency={currency}
        departureDate={departureDate}
        destinations={destinations}
        isOneWay={isOneWay}
        origins={origins}
        paxTypeAges={paxTypeAges}
        residency={residency}
        returnDate={returnDate}
      >
        <StyledCover
          alignContent={useFloatingSearchWidget ? 'flex-end' : 'center'}
          backgroundTextColor={backgroundTextColor}
          cover={image}
          hasCoverContent={
            Boolean(image) || useFloatingSearchWidget || Boolean(text)
          }
          showTitleOnTablets={showTitleOnTablets}
          spaceAbove={spaceAbove}
          spaceBelow={spaceBelow}
          subtitleColor={subtitleColor}
          text={text as Maybe<StructuredTextType>}
          textAlign={textAlign as Maybe<AlignmentOptions>}
          titleColor={titleColor}
          titleTypography={titleTypography}
        >
          {!useFloatingSearchWidget ? (
            <MediaQuery toDisplay={DisplayType.Medium}>
              <FrontPageSearchWidgetContainer
                showTitleOnTablets={showTitleOnTablets}
              />
            </MediaQuery>
          ) : null}
          {useFloatingSearchWidget ? (
            <FrontPageSearchWidgetContainer
              showTitleOnTablets={showTitleOnTablets}
            />
          ) : null}
        </StyledCover>
        {!useFloatingSearchWidget ? (
          <MediaQuery fromDisplay={DisplayType.Medium}>
            <FrontPageSearchWidgetContainer
              showTitleOnTablets={showTitleOnTablets}
            />
          </MediaQuery>
        ) : null}
      </SearchWidgetProvider>
    </SearchWidgetErrorBoundary>
  );
};

export default FrontPageCoverContainer;
