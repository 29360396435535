import { ReactNode, CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import { ImageFragment, SpecificTypographyFragment } from '@codegen/cmsUtils';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import { legacySpacings } from '@ui-v2/theme/layout';
import {
  createLegacyTypography,
  createTypography,
  mqMin,
} from '../..//styles/base';
import { getImageSizes } from '../../utils/getImageSizes';
import StructuredTextWrapper from '../Content/StructuredTextWrapper';
import { GridWrap, GridRow, GridColumn } from '../Grid/Grid';
import Image from '../Image';

export interface Props {
  alignContent?: CSSProperties['alignItems'];
  backgroundTextColor?: Maybe<string>;
  children?: ReactNode;
  className?: string;
  cover: Maybe<ImageFragment>;
  showTitleOnTablets?: Maybe<boolean>;
  subtitleColor?: string | null;
  text?: Maybe<StructuredTextType>;
  textAlign?: Maybe<AlignmentOptions>;
  titleColor?: string | null;
  titleTypography?: Maybe<SpecificTypographyFragment>;
}

const Container = styled('div')<{ alignContent?: Props['alignContent'] }>(
  ({ alignContent }) => css`
    position: relative;
    display: flex;
    align-items: center;

    ${mqMin.Small} {
      align-items: ${alignContent || 'center'};
    }
  `,
);

const StyledGridWrap = styled(GridWrap)<{
  showTitleOnTablets?: Maybe<boolean>;
}>(({ showTitleOnTablets, theme: { spacings } }) => [
  css`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: ${legacySpacings.Medium}px;
  `,
  showTitleOnTablets &&
    css`
      flex-direction: row-reverse;
      padding: ${legacySpacings.ExtraExtraLarge}px ${legacySpacings.Medium}px
        ${spacings['24']}px;

      ${mqMin.Small} {
        padding: ${legacySpacings.Medium}px ${spacings['24']}px
          ${legacySpacings.ExtraExtraLarge}px;
        gap: ${2 * legacySpacings.ExtraLarge}px;
      }

      ${mqMin.Medium} {
        flex-direction: column;
        padding: 0;
      }
    `,
]);

const StyledGridRow = styled(GridRow)<{
  backgroundTextColor?: Props['backgroundTextColor'];
}>(({ backgroundTextColor, theme: { shape, spacings } }) => [
  css`
    display: none;

    ${mqMin.Small} {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: ${shape.borderRadiusS}px;
      gap: ${spacings['16']}px;
    }
  `,
  backgroundTextColor &&
    css`
      ${mqMin.Small} {
        max-width: 500px;
        padding: ${spacings['24']}px 0;
        border-radius: ${shape.borderRadiusS}px;
        background-color: ${backgroundTextColor};
      }
    `,
]);

const Cover = ({
  alignContent,
  backgroundTextColor,
  children,
  className,
  cover,
  showTitleOnTablets,
  subtitleColor,
  text,
  textAlign,
  titleColor,
  titleTypography,
}: Props) => {
  if (!cover && !text && !children) {
    return null;
  }

  const fixedImageSize = Boolean(
    cover?.width && cover.height && cover.width / cover.height > 3,
  );

  return (
    <Container alignContent={alignContent} className={className}>
      {cover?.url && (
        <Image
          alt={cover.alt}
          fill
          focalPoint={cover.focalPoint}
          objectFit="cover"
          priority
          quality={75}
          sizes={
            fixedImageSize ? getImageSizes({ Default: '1920px' }) : undefined
          }
          src={cover.url}
        />
      )}
      <StyledGridWrap showTitleOnTablets={showTitleOnTablets}>
        {text && (
          <StyledGridRow backgroundTextColor={backgroundTextColor}>
            <GridColumn>
              <StructuredTextWrapper
                data={text}
                h1Styles={({ colours, typography }) => [
                  titleTypography
                    ? createLegacyTypography(titleTypography)
                    : createTypography(typography.heading02),
                  css`
                    margin: 0;
                    color: ${titleColor ||
                    colours.text.on.interactive.primary.default};
                    text-align: ${textAlign || 'unset'};
                  `,
                ]}
                h2Styles={({ colours, spacings, typography }) => [
                  createTypography(typography.body01),
                  css`
                    margin-top: ${spacings['8']}px;
                    color: ${subtitleColor ||
                    titleColor ||
                    colours.text.on.interactive.primary.default};
                    text-align: ${textAlign || 'unset'};
                  `,
                ]}
              />
            </GridColumn>
          </StyledGridRow>
        )}
        {children && children}
      </StyledGridWrap>
    </Container>
  );
};

export default Cover;
