import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorFragment, ImageFragment } from '@codegen/cmsUtils';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import { legacySpacings } from '@ui-v2/theme/layout';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { createTypography, centerVertically } from '../../styles/base';
import { getImageSizes } from '../../utils/getImageSizes';
import Image from '../Image';

export interface Props {
  children: React.ReactNode | React.ReactNode[];
  cover: Maybe<ImageFragment>;
  gradient: Maybe<string>;
  textAlign: Maybe<AlignmentOptions>;
  title: Maybe<string>;
  titleBackgroundColor: Maybe<ColorFragment>;
  titleBackgroundGradient: Maybe<number>;
}

const Container = styled.div(({ theme: { shadows, shape, typography } }) => [
  createTypography(typography.body01),
  css`
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    border-radius: ${shape.borderRadiusS}px;
    box-shadow: ${shadows.medium};
  `,
]);

const TitleContainer = styled.div<{
  gradient: Maybe<string>;
  titleBackgroundColor: Maybe<ColorFragment>;
  titleBackgroundGradient: Maybe<number>;
}>(
  ({
    gradient,
    theme: { colours, spacings, typography },
    titleBackgroundColor,
    titleBackgroundGradient,
  }) => [
    createTypography(typography.heading03),
    centerVertically,
    css`
      z-index: ${legacyZIndices.z1};
      padding: ${spacings['8']}px;
      background: ${titleBackgroundColor
        ? titleBackgroundColor.main
        : colours.brand.primary};
      color: ${colours.text.on.interactive.primary.default};
      opacity: ${titleBackgroundGradient ? titleBackgroundGradient : 0.8};
    `,
    gradient &&
      css`
        height: 100%;
        align-items: flex-end;
        padding: ${legacySpacings.Medium}px;
        background: ${gradient};
        opacity: unset;
      `,
  ],
);

const Title = styled.div<{ isGradient: boolean; textAlign: Maybe<string> }>(
  ({ isGradient, textAlign, theme: { typography } }) => [
    createTypography(typography.heading03),
    css`
      width: 100%;
      text-align: ${textAlign ? textAlign : 'center'};
    `,
    isGradient &&
      css`
        font-size: 24px;
      `,
  ],
);

const ImageContainer = styled.div(css`
  position: relative;
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: column;
  justify-content: flex-end;

  > img {
    height: 100%;
  }
`);

const Content = styled.div(
  ({ theme: { colours } }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background: ${colours.brand.secondary};
    color: ${colours.text.on.interactive.secondary.default};

    > a {
      color: ${colours.text.on.interactive.secondary.default};
    }
  `,
);

const Card = ({
  children,
  cover,
  gradient,
  textAlign,
  title,
  titleBackgroundColor,
  titleBackgroundGradient,
}: Props) => (
  <Container>
    {cover && title && (
      <ImageContainer>
        <Image
          alt={cover.alt}
          fill
          objectFit="cover"
          quality={75}
          sizes={getImageSizes({
            Small: '100vw',
            Large: '50vw',
            Default: '320px',
          })}
          src={cover.url}
        />
        <TitleContainer
          gradient={gradient}
          titleBackgroundColor={titleBackgroundColor}
          titleBackgroundGradient={titleBackgroundGradient}
        >
          <Title isGradient={!!gradient} textAlign={textAlign}>
            {title}
          </Title>
        </TitleContainer>
      </ImageContainer>
    )}

    <Content>{children}</Content>
  </Container>
);

export default Card;
