import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ColorFragment,
  ImageCardFragment,
  SpecificTypographyFragment,
} from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import ImageCard, {
  Title,
  Description,
  ImageContainer,
  TextContainer,
} from '@ui/components/Card/ImageCard';
import { BaseGridColumn, LayoutGrid } from '@ui/components/Grid/Grid';
import { tileStyles } from '@ui/components/Tile/Tile';
import { createLegacyTypography, mqMin } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';

interface ExtendedImageCardFragment extends ImageCardFragment {
  imageObjectFit?: CSSProperties['objectFit'];
}

export interface HowDoesItWorkSectionV2Props {
  imageCards: ExtendedImageCardFragment[];
  isTile?: Maybe<boolean>;
}

const StyledCard = styled(ImageCard)<{
  descriptionColor?: Maybe<ColorFragment>;
  isTile?: Maybe<boolean>;
  titleColor?: Maybe<ColorFragment>;
  titleTypography?: Maybe<SpecificTypographyFragment>;
}>(({ descriptionColor, isTile, theme, titleColor, titleTypography }) => [
  css`
    flex-direction: row;
    align-items: flex-start;

    ${TextContainer} {
      margin-top: 0;
    }

    ${Title} {
      margin-bottom: ${theme.spacings['8']}px;
      text-align: left;
      ${titleTypography && createLegacyTypography(titleTypography)}
    }

    ${Description} {
      line-height: 1.2;
      text-align: left;
    }

    ${BaseGridColumn} {
      width: 80%;
      padding-left: ${theme.spacings['8']}px;
      margin-top: 0;
    }

    ${mqMin.Small} {
      ${BaseGridColumn} {
        width: 90%;
        padding-right: 0;
      }
    }

    ${mqMin.Medium} {
      ${ImageContainer} {
        width: 80px;
        height: 65px;
      }
    }
  `,
  titleColor &&
    css`
      ${Title} {
        color: ${titleColor.main};
      }
    `,
  descriptionColor &&
    css`
      ${Description} {
        color: ${descriptionColor.main};
      }
    `,
  isTile && tileStyles(theme),
]);

const HowDoesItWorkSectionV2 = ({
  imageCards,
  isTile,
}: HowDoesItWorkSectionV2Props) => (
  <LayoutGrid
    gap={legacySpacings.ExtraExtraLarge}
    gridTemplateColumns={[
      'auto',
      'auto',
      'auto',
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
      `repeat(${imageCards.length}, 1fr)`,
    ]}
    px={[
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Medium,
      DisplayType.Large,
    ]}
  >
    {imageCards.map((imageCard) => (
      <StyledCard
        description={imageCard.description?.value}
        descriptionColor={imageCard.descriptionColor}
        direction="row"
        image={imageCard.image}
        isTile={isTile}
        key={imageCard.id}
        objectFit={imageCard.imageObjectFit}
        title={imageCard.title?.value}
        titleColor={imageCard.titleColor}
        titleTypography={imageCard.titleTypography}
      />
    ))}
  </LayoutGrid>
);

export default HowDoesItWorkSectionV2;
