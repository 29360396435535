import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import { CollapsibleSectionContainerFragment } from '@codegen/cmsUtils';
import Accordion from '@ui/components/Accordion/Accordion';
import StructuredTextWrapper from '@ui/components/Content/StructuredTextWrapper';
import Paragraph from '@ui/components/Paragraph/Paragraph';
import { createStructredDataFromCollapsableSection } from './utils/frontPageUtils';

const StyledAccordionWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.spacings['24']}px;
`;

interface Props {
  data?: CollapsibleSectionContainerFragment;
  isTile: Maybe<boolean>;
}

const CollapsibleSectionContainer = ({ data, isTile }: Props) => {
  if (!data) {
    return null;
  }
  const { collapsibleSection, faqHeadingColor } = data;

  return (
    <div>
      <script
        dangerouslySetInnerHTML={{
          __html: createStructredDataFromCollapsableSection(collapsibleSection),
        }}
        type="application/ld+json"
      />

      <StyledAccordionWrapper>
        <Accordion
          headingColor={faqHeadingColor?.main}
          iconSize="large"
          items={collapsibleSection.map((items) => ({
            content: (
              <>
                {items.faqText.map((item) => {
                  if (item.__typename === 'StructuredTextRecord') {
                    return (
                      <StructuredTextWrapper
                        data={item.content as Maybe<StructuredText>}
                        key={`${item.id}-text`}
                      />
                    );
                  }

                  if (item.value) {
                    return (
                      <Paragraph key={`${item.id}-text`}>
                        {item.value}
                      </Paragraph>
                    );
                  }

                  return null;
                })}
              </>
            ),
            heading: items.faqHeading.value,
          }))}
          type="multiple"
          variant={isTile ? 'tiles' : 'blocks'}
        />
      </StyledAccordionWrapper>
    </div>
  );
};

export default CollapsibleSectionContainer;
