import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VendorCarouselFragment } from '@codegen/cmsUtils';
import Carousel from '@ui/components/Carousel/Carousel';
import { GridColumn, GridRow } from '@ui/components/Grid/Grid';
import Image from '@ui/components/Image';
import VendorCarouselCard from './VendorCarouselCard';

interface Props {
  vendors: VendorCarouselFragment['vendorInfo'];
}

const SliderItemContainer = styled.div(
  ({ theme: { spacings } }) => css`
    position: relative;
    display: flex;
    height: 42px;
    align-items: center;
    justify-content: center;
    margin: ${spacings['8']}px;
  `,
);

const StyledImage = styled(Image)`
  width: auto;
  height: 100%;
`;

const VendorCarouselWrapper = ({ vendors }: Props) => (
  <GridRow display="block">
    <GridColumn>
      <Carousel
        items={vendors.map(({ connectVia, travelTo, vendor }) => ({
          id: vendor?.iata ?? '',
          sliderItem: vendor?.logo.url ? (
            <SliderItemContainer key={`${vendor.id}slider`}>
              <StyledImage
                alt={vendor.name}
                height={vendor.logo.height}
                objectFit="contain"
                src={vendor.logo.url}
                width={vendor.logo.width}
              />
            </SliderItemContainer>
          ) : (
            <div />
          ),
          CardItem:
            vendor && connectVia.length > 0 && travelTo.length > 0 ? (
              <VendorCarouselCard
                connectVia={connectVia.map((item) => item.value)}
                key={`${vendor.id}card`}
                travelTo={travelTo.map((item) => item.value)}
                vendorLogo={vendor.logo}
                vendorName={vendor.name}
              />
            ) : (
              <div />
            ),
        }))}
      />
    </GridColumn>
  </GridRow>
);

export default VendorCarouselWrapper;
