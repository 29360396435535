import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as RxAccordion from '@radix-ui/react-accordion';
import Box from '@ui-v2/core/Box/Box';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/tiny-down-arrow.svg';
import { createTypography, shouldForwardProp } from '../../styles/base';
import { resetButton } from '../../styles/cssReset';

type AccordionVariant = 'blocks' | 'tiles' | 'raw';
type IconSize = 'small' | 'medium' | 'large';

const getIconSize = (iconSize?: IconSize) => {
  switch (iconSize) {
    case 'small':
      return {
        width: 12,
        height: 12,
      };
    case 'medium':
      return {
        width: 14,
        height: 14,
      };
    case 'large':
      return {
        width: 16,
        height: 16,
      };
  }
};

const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-accordion-content-height); }
`;

const slideUp = keyframes`
  from { height: var(--radix-accordion-content-height); }
  to { height: 0; }
`;

const StyledAccordionContainer = styled(RxAccordion.Root, {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['variant'] }),
})<{
  variant: AccordionVariant;
}>(({ theme: { spacings }, variant }) => [
  variant === 'raw' &&
    css`
      display: flex;
      flex-direction: column;
      gap: ${spacings['12']}px;
    `,
]);

const StyledAccordionItem = styled(RxAccordion.AccordionItem)<{
  variant: AccordionVariant;
}>(({ theme: { colours, shadows, shape, spacings }, variant }) => [
  variant === 'blocks' &&
    css`
      border-bottom: 1px solid ${colours.border.default};
      transition: border-color 250ms ease;

      &:hover {
        border-color: ${colours.border.interactive};
      }
    `,
  variant === 'tiles' &&
    css`
      padding: 0 ${spacings['16']}px;
      border: 1px solid ${colours.border.subdued};
      border-radius: ${shape.borderRadiusS}px;
      background: ${colours.surface.selected.default};
      box-shadow: ${shadows.medium};

      &:not(:last-child) {
        margin-bottom: ${spacings['8']}px;
      }
    `,
]);

const StyledAccordionHeader = styled.span<{ headingColor?: string }>(
  ({ headingColor, theme: { colours, typography } }) => [
    createTypography(typography.heading05),
    css`
      color: ${headingColor ?? colours.text.default};
    `,
  ],
);

const StyledAccordionHeading = styled.div<{ variant?: AccordionVariant }>(
  ({ theme: { spacings } }) => [
    css`
      display: flex;
      flex-grow: 1;
      margin-right: ${spacings['8']}px;
      overflow-x: hidden;
    `,
  ],
);

const StyledAccordionTrigger = styled(RxAccordion.Trigger)<{
  variant: AccordionVariant;
}>(({ theme, variant }) => [
  resetButton,
  css`
    width: 100%;
    flex: 0 0 auto;
    padding: ${theme.spacings['16']}px 0;
    cursor: pointer;
    text-align: left;
  `,
  variant === 'raw' &&
    css`
      padding: 0;
    `,
]);

const StyledDownArrowIcon = styled(DownArrowIcon, {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['faqHeadingColor'] }),
})<{
  headingColor?: string;
  iconSize?: IconSize;
}>(({ headingColor, iconSize, theme: { colours } }) => [
  css`
    flex: 0 0 auto;
    fill: ${headingColor || colours.icons.subdued};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  `,
  css(getIconSize(iconSize)),
]);

const StyledAccordionContent = styled(RxAccordion.Content)`
  overflow: hidden;

  [data-state='open'] & {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  [data-state='closed'] & {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const StyledAccordionContentInner = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings['16']}px;
`;

interface AccordionItem {
  /** The content of the accordion item, shown when expanded */
  content: React.ReactNode;
  /** An optional react node for added content below the heading */
  description?: React.ReactNode;
  /** The heading of the item, shown in the accordion item's header */
  heading: React.ReactNode;
  /** An optional key value for the accordion item */
  value?: string;
}

export interface AccordionProps {
  /** An optional heading color, fallbacks to main text */
  headingColor?: string;
  /** */
  iconSize?: IconSize;
  /** An array of accordion items, */
  items: AccordionItem[];
  /** Allow a single or multiple items to be open at once */
  type?: 'single' | 'multiple';
  /** The style variant of the accordion */
  variant?: AccordionVariant;
}

const Accordion = ({
  headingColor,
  iconSize = 'medium',
  items,
  type = 'multiple',
  variant = 'blocks',
}: AccordionProps) => {
  return (
    <StyledAccordionContainer type={type} variant={variant}>
      {items.map((item, index) => (
        <StyledAccordionItem
          key={item.value ?? `Item-${index}`}
          value={item.value ?? `Item-${index}`}
          variant={variant}
        >
          <RxAccordion.Header asChild>
            <StyledAccordionHeader headingColor={headingColor}>
              <StyledAccordionTrigger variant={variant}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <StyledAccordionHeading variant={variant}>
                    {item.heading}
                  </StyledAccordionHeading>
                  <StyledDownArrowIcon
                    headingColor={headingColor}
                    iconSize={iconSize}
                  />
                </Box>
                {item.description && <Box>{item.description}</Box>}
              </StyledAccordionTrigger>
            </StyledAccordionHeader>
          </RxAccordion.Header>
          <StyledAccordionContent>
            <StyledAccordionContentInner>
              {item.content}
            </StyledAccordionContentInner>
          </StyledAccordionContent>
        </StyledAccordionItem>
      ))}
    </StyledAccordionContainer>
  );
};

export default Accordion;
