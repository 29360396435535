import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mqMin } from '@ui/styles/base';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import SearchWidgetContainer from '../Search/SearchWidget/SearchWidgetContainer';
import useGetFrontPageData from './hooks/useGetFrontPageData';

export interface Props {
  showTitleOnTablets?: Maybe<boolean>;
}

const Container = styled.div<{
  useFloatingSearchWidget?: boolean;
}>(({ useFloatingSearchWidget }) => [
  css`
    width: 100%;
  `,
  useFloatingSearchWidget &&
    css`
      ${mqMin.Small} {
        display: flex;
        justify-content: center;
      }
    `,
]);

const StyledSearchWidgetContainer = styled(SearchWidgetContainer)<{
  useFloatingSearchWidget?: boolean;
}>(
  ({ theme: { shape, sizes }, useFloatingSearchWidget }) =>
    useFloatingSearchWidget &&
    css`
      width: 100%;
      max-width: ${sizes.container}px;
      border-radius: ${shape.searchWidgetBorderRadius}px;
    `,
);

const FrontPageSearchWidgetContainer = (props: Props) => {
  const { showTitleOnTablets } = props;

  const {
    cover: { useFloatingSearchWidget },
  } = useGetFrontPageData();

  const [inTransition, setInTransition] = useState(false);
  const { events } = usePartnerRouter();

  useEffect(() => {
    const handleTransition = () => {
      setInTransition(true);
    };

    events.on('routeChangeStart', handleTransition);

    return () => {
      events.off('routeChangeStart', () => handleTransition);
    };
  }, [setInTransition, events]);

  return (
    <Container useFloatingSearchWidget={useFloatingSearchWidget}>
      <StyledSearchWidgetContainer
        inTransition={inTransition}
        showTitleOnTablets={showTitleOnTablets}
        useFloatingSearchWidget={useFloatingSearchWidget}
      />
    </Container>
  );
};

export default FrontPageSearchWidgetContainer;
