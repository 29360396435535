import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorFragment, ImageFragment } from '@codegen/cmsUtils';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import { GridRow, GridColumn } from '@ui/components/Grid/Grid';
import Image from '@ui/components/Image';
import Title, { Heading } from '@ui/components/Title/Title';

interface Props {
  alignment?: Maybe<AlignmentOptions>;
  backgroundColor?: Maybe<ColorFragment>;
  borderRadius?: Maybe<number>;
  color?: Maybe<ColorFragment>;
  image: Maybe<ImageFragment>;
  title: string;
}

const StyledTitle = styled(Title)<{
  alignment?: Maybe<AlignmentOptions>;
  backgroundColor?: Maybe<ColorFragment>;
  borderRadius?: Maybe<number>;
  color?: Maybe<ColorFragment>;
}>(
  ({
    alignment,
    backgroundColor,
    borderRadius,
    color,
    theme: { spacings },
  }) => [
    css`
      justify-content: ${alignment ? alignment : ''};
      text-align: ${alignment ? alignment : ''};
    `,
    color &&
      css`
        ${Heading} {
          color: ${color.main};
        }
      `,
    backgroundColor &&
      css`
        ${Heading} {
          padding: ${spacings['16']}px;
          background-color: ${backgroundColor.main};
        }
      `,
    borderRadius &&
      css`
        ${Heading} {
          border-radius: ${borderRadius}px;
        }
      `,
  ],
);

const TitleWrapper = ({
  alignment,
  backgroundColor,
  borderRadius,
  color,
  image,
  title,
}: Props) => {
  return (
    <GridRow display="block">
      <GridColumn>
        <StyledTitle
          alignment={alignment}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          color={color}
          headingStyle="h3"
          icon={
            image && (
              <Image
                alt={image.alt}
                height={60}
                objectFit="contain"
                src={image.url}
                width={60}
              />
            )
          }
        >
          {title}
        </StyledTitle>
      </GridColumn>
    </GridRow>
  );
};

export default TitleWrapper;
