import {
  ApplicationStringFragment,
  CardFragment,
  ColorFragment,
  LinkFragment,
} from '@codegen/cmsUtils';
import { DisplayType } from '@shared/types/enums';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import Card from '@ui/components/Card/Card';
import CardV2 from '@ui/components/Card/CardV2';
import { GridColumn, GridRow } from '@ui/components/Grid/Grid';
import Link from '@ui/components/Link/Link';
import { getEqualColumnWidth } from '@ui/styles/utils';
import { legacySpacings } from '@ui-v2/theme/layout';

interface Props {
  cards: Maybe<CardFragment[]>;
  gradient: Maybe<string>;
  textAlign: Maybe<AlignmentOptions>;
  titleBackgroundColor: Maybe<ColorFragment>;
  titleBackgroundGradient: Maybe<number>;
  variant: Maybe<string>;
}

const CardItem = ({
  item,
}: {
  item: ApplicationStringFragment | LinkFragment;
}) => {
  switch (item.__typename) {
    case 'ApplicationStringRecord':
      return <div key={item.id}>{item.value}</div>;
    case 'LinkRecord':
      return (
        <Link href={item.href} target={item.target ?? undefined}>
          {item.label.value}
        </Link>
      );
    default:
      return <span />;
  }
};

const CardWrapper = ({
  cards,
  gradient,
  textAlign,
  titleBackgroundColor,
  titleBackgroundGradient,
  variant,
}: Props) => {
  if (!cards) {
    return null;
  }

  const useIcons = variant === 'use-icons';

  const numberOfColumns =
    cards.length >= 4
      ? Math.ceil(cards.length / Math.ceil(cards.length / 4))
      : cards.length;

  return (
    <GridRow
      gap={legacySpacings.ExtraExtraLarge}
      justify="center"
      px={[
        DisplayType.Medium,
        DisplayType.Medium,
        DisplayType.Medium,
        DisplayType.Medium,
        DisplayType.Large,
      ]}
      wrap="wrap"
    >
      {cards.map((cardInfo) => (
        <GridColumn
          display="flex"
          key={cardInfo.id}
          px={0}
          width={[
            12,
            12,
            12,
            getEqualColumnWidth({
              columns: 2,
              gap: legacySpacings.ExtraExtraLarge,
            }),
            getEqualColumnWidth({
              columns: numberOfColumns,
              gap: legacySpacings.ExtraExtraLarge,
            }),
          ]}
        >
          {useIcons ? (
            <CardV2 icon={cardInfo.image} title={cardInfo.title.value}>
              {cardInfo.items.map((item) => {
                return <CardItem item={item} key={item.id} />;
              })}
            </CardV2>
          ) : (
            <Card
              cover={cardInfo.image}
              gradient={gradient}
              textAlign={textAlign}
              title={cardInfo.title.value}
              titleBackgroundColor={titleBackgroundColor}
              titleBackgroundGradient={titleBackgroundGradient}
            >
              {cardInfo.items.map((item) => {
                return <CardItem item={item} key={item.id} />;
              })}
            </Card>
          )}
        </GridColumn>
      ))}
    </GridRow>
  );
};

export default CardWrapper;
