/**
 * Calculates the equal width of a specified number of columns with a gap in between, without wrapping.
 * @param columns The number of columns to calculate the equal width for.
 * @param gap The size of the gap between the columns in pixels.
 * @param maxColumns The maximum number of columns to consider. Defaults to 4.
 * @returns A CSS calc() expression that gives the equal width of the columns while considering the gap between them.
 */
export const getEqualColumnWidth = ({
  columns,
  gap,
  maxColumns = 4,
}: {
  columns: number;
  gap: number;
  maxColumns?: number;
}) => {
  const columnsValue = columns <= maxColumns ? columns : maxColumns;

  return `calc((100% / ${columnsValue}) - (${gap}px * ${
    columnsValue - 1
  } / ${columnsValue}))`;
};
