import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageFragment } from '@codegen/cmsUtils';
import { createTypography, mqMin } from '../../styles/base';
import { getImageSizes } from '../../utils/getImageSizes';
import { GridColumn, GridRow } from '../Grid/Grid';
import { DirectionProp } from '../Grid/gridTypes';
import Image from '../Image';

export interface Props {
  className?: string;
  description: Maybe<string>;
  direction?: DirectionProp;
  image: Maybe<ImageFragment>;
  objectFit?: CSSProperties['objectFit'];
  title: Maybe<string>;
}

export const ImageContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;

  ${mqMin.ExtraExtraSmall} {
    width: 66px;
    height: 66px;
  }

  ${mqMin.Large} {
    width: 130px;
    height: 130px;
  }
`;

export const Title = styled.h3(({ theme: { typography } }) =>
  createTypography(typography.heading04),
);

export const TextContainer = styled.div(
  ({ theme: { colours, spacings } }) => css`
    margin-top: 0;
    color: ${colours.text.default};
    text-align: unset;

    ${mqMin.Small} {
      margin-top: ${spacings['16']}px;
      text-align: center;
    }
  `,
);

export const Description = styled.div(({ theme: { typography } }) =>
  createTypography(typography.body02),
);

const ImageCard = ({
  className,
  description,
  direction = ['row', 'row', 'row', 'column'],
  image,
  objectFit = 'contain',
  title,
}: Props) => (
  <GridRow align="center" className={className} direction={direction}>
    {image && (
      <ImageContainer>
        <Image
          alt={image.alt}
          fill
          objectFit={objectFit}
          sizes={getImageSizes({ Default: '200px' })}
          src={image.url}
        />
      </ImageContainer>
    )}
    <GridColumn width={[9, 9, 9, 12]}>
      <TextContainer>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </TextContainer>
    </GridColumn>
  </GridRow>
);

export default ImageCard;
